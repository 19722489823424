define("discourse/plugins/discourse-ratings/discourse/controllers/admin-plugins-ratings", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service", "I18n", "discourse/plugins/discourse-ratings/discourse/models/rating-type"], function (_exports, _controller, _computed, _service, _I18n, _ratingType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    dialog: (0, _service.inject)(),
    hasTypes: (0, _computed.notEmpty)("ratingTypes"),
    actions: {
      newType() {
        this.get("ratingTypes").pushObject(_ratingType.default.create({
          isNew: true,
          type: "",
          name: ""
        }));
      },
      addType(typeObj) {
        let data = {
          type: typeObj.type,
          name: typeObj.name
        };
        this.set("loading", true);
        _ratingType.default.add(data).then(result => {
          if (result.success) {
            this.send("refresh");
          } else {
            typeObj.set("hasError", true);
            this.set("loading", false);
          }
        });
      },
      updateType(typeObj) {
        let data = {
          name: typeObj.name
        };
        this.set("loading", true);
        _ratingType.default.update(typeObj.type, data).then(result => {
          if (result.success) {
            this.send("refresh");
          } else {
            typeObj.set("hasError", true);
            this.set("loading", false);
          }
        });
      },
      destroyType(typeObj) {
        if (typeObj.isNew) {
          this.get("ratingTypes").removeObject(typeObj);
        } else {
          this.dialog.yesNoConfirm({
            message: _I18n.default.t("admin.ratings.type.confirm_destroy"),
            didConfirm: () => {
              this.set("loading", true);
              _ratingType.default.destroy(typeObj.type).then(response => {
                if (response.success) {
                  this.send("refresh");
                } else {
                  typeObj.set("hasError", true);
                  this.set("loading", false);
                }
              });
            }
          });
        }
      }
    }
  });
});