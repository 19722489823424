define("discourse/plugins/discourse-ratings/discourse/lib/rating-utilities", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-owner", "I18n"], function (_exports, _ajax, _ajaxError, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ratingListHtml = ratingListHtml;
  _exports.request = request;
  let starRatingRaw = function (rating) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let content = "";
    for (let i = 0; i < 5; i++) {
      let value = i + 1;
      let checked = value <= rating ? "checked" : "";
      let disabled = opts.enabled ? "" : " disabled";
      let star = "";
      if (opts.clickable) {
        star += '<span class="' + checked + disabled + '"></span>';
      } else {
        star += '<input class="' + disabled + '"type="radio" value="' + value + '" ' + checked + disabled + ">";
      }
      star += "<i></i>";
      content = content.concat(star);
    }
    return '<span class="star-rating">' + content + "</span>";
  };
  function ratingHtml(rating) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let html = "";
    let title = "";
    let link = null;
    const name = rating.type_name;
    if (name) {
      html += `<span class="rating-type">${name}</span>`;
      title += `${name} `;
    }
    let value = Math.round(rating.value * 10) / 10;
    html += starRatingRaw(value);
    title += value;
    if (opts.topic) {
      link = opts.topic.url;
      const siteSettings = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site-settings");
      if (siteSettings.rating_show_numeric_average) {
        html += `<span class="rating-value">(${value})</span>`;
      }
      if (siteSettings.rating_show_count) {
        let count = rating.count;
        let countLabel = _I18n.default.t("topic.x_rating_count", {
          count
        });
        html += `<span class="rating-count">${count} ${countLabel}</span>`;
        title += ` ${count} ${countLabel}`;
      }
    }
    if (opts.linkTo && link) {
      return `<a href="${link}" class="rating" title="${title}">${html}</a>`;
    } else {
      return `<div class="rating" title="${title}">${html}</div>`;
    }
  }
  function ratingListHtml(ratings) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (typeof ratings === "string") {
      try {
        ratings = JSON.parse(ratings);
      } catch (e) {
        ratings = null;
      }
    }
    if (!ratings) {
      return "";
    }
    let html = "";
    ratings.forEach(rating => {
      let showRating = opts.topic ? rating.count > 0 : rating.weight > 0;
      if (showRating) {
        html += ratingHtml(rating, opts);
      }
    });
    return `<div class="rating-list">${html}</div>`;
  }
  function request(type) {
    let path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return (0, _ajax.ajax)(`/ratings/${path}`, {
      type,
      data
    }).catch(_ajaxError.popupAjaxError);
  }
});