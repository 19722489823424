define("discourse/plugins/discourse-ratings/discourse/components/topic-rating-tip", ["exports", "@ember/component", "@ember/runloop", "jquery"], function (_exports, _component, _runloop, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "topic-rating-tip",
    didInsertElement() {
      this._super(...arguments);
      (0, _jquery.default)(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _jquery.default)(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      let $element = (0, _jquery.default)(this.element);
      let $target = (0, _jquery.default)(e.target);
      if ($target.closest($element).length < 1 && this._state !== "destroying") {
        this.set("showDetails", false);
      }
    },
    actions: {
      toggleDetails() {
        this.toggleProperty("showDetails");
      }
    }
  });
});